import ModalApp from './DraggableModal'
import Modal from 'react-bootstrap/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../store/actions/baseActions'
import st from './Modal.module.scss'
import { memo } from 'react'

const Changelog = memo(() => {
	const isVisible = useSelector((state) => state?.modals.changeLog)
	const dispatch = useDispatch()

	return (
		<ModalApp
			show={isVisible}
			size={'lg'}
			centered={true}
			backdrop={true}
			scrollable={true}
			keyboard={true}
			className={st.changeLogModal}
			onHide={() => dispatch(closeModal('changeLog'))}
		>
			<Modal.Header closeButton={true} closeVariant={'white'}>
				<Modal.Title>განახლების ისტორია</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.4.26</div>
						<div className={st.date}>17.01.2025</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>გაეშვა პრომო აქცია</li>
						</ul>
					</div>
				</article>
				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.4.25</div>
						<div className={st.date}>11.12.2024</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>გაეშვა პრომო აქცია</li>
						</ul>
					</div>
				</article>
				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.4.24</div>
						<div className={st.date}>12.03.2024</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>დასრულდა პრომო აქცია</li>
						</ul>
					</div>
				</article>
				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.4.23</div>
						<div className={st.date}>26.02.2024</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>განახლდა აქციის ბანერი</li>
						</ul>
					</div>
				</article>
				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.4.22</div>
						<div className={st.date}>19.02.2024</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>
								გამოსწორდა ხარვეზი: <br /> რიგ შემთხვევებში, გვერდის
								განახლების შემდეგ, აქტიურ ბილეთზე ქეშაუთი არ მუშაობს
							</li>
						</ul>
					</div>
				</article>
				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.4.21</div>
						<div className={st.date}>15.02.2024</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>გასწორდა ხარვეზი</li>
							<li>განახლდა წესების გვერდი</li>
						</ul>
					</div>
				</article>
				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.4.20</div>
						<div className={st.date}>30.01.2024</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>განახლდა აქციის ბანერი</li>
						</ul>
					</div>
				</article>
				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.4.19</div>
						<div className={st.date}>12.01.2024</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>დასრულდა პრომო აქცია</li>
						</ul>
					</div>
				</article>
				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.4.18</div>
						<div className={st.date}>15.12.2023</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>განახლების მიზნით დროებით გაითიშა ჩათი</li>
						</ul>
					</div>
				</article>
				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.4.17</div>
						<div className={st.date}>08.12.2023</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>განახლდა აქციის ბანერი</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.4.15</div>
						<div className={st.date}>20.10.2023</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>განახლდა შეტყობინების ტექსტი</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.4.14</div>
						<div className={st.date}>17.10.2023</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>გასწორდა ხარვეზი ბილეთში</li>
							<li>შესრულდა ბილეთების ოპტიმიზაცია</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.4.13</div>
						<div className={st.date}>13.10.2023</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>
								გასწორდა პრობლემა როცა ქსელის გათიშვისას გრაფიკი
								აგრძელებდა უსასრულოდ ზრდას
							</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.4.12</div>
						<div className={st.date}>22.08.2023</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>დასრულდა პრომო აქცია</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.4.11</div>
						<div className={st.date}>10.08.2023</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>განახლდა აქციის ბანერი</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.4.10</div>
						<div className={st.date}>21.06.2023</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>გასწორდა მცირედი ხარვეზები</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.4.9</div>
						<div className={st.date}>10.05.2023</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>გასწორდა მცირედი ხარვეზები</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.4.8</div>
						<div className={st.date}>01.05.2023</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>დასრულდა პრომო აქცია</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.4.7</div>
						<div className={st.date}>05.04.2023</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>გაეშვა პრომო აქცია</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.4.6</div>
						<div className={st.date}>18.02.2023</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>დასრულდა აქცია "იღბლიანი დღეები"</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.4.5</div>
						<div className={st.date}>02.02.2023</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>აქცია "იღბლიანი დღეები"</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.4.4</div>
						<div className={st.date}>27.12.2022</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>შეიცვალა პრომოს ბანერები</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.4.3</div>
						<div className={st.date}>05.12.2022</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>დაემატა პრომოს ბანერი</li>
							<li>გასწორდა ჯეკპოტის ხმის პრობლემა iOS მოწყობილობებზე</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.4.2</div>
						<div className={st.date}>21.04.2022</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>
								"გააუქმე ყველა" ღილაკი აქტიურდება მხოლოდ იმ შემთხვევაში,
								თუ არის განთავსებული ბილეთები, რომელიც ჯერ არ მიღებულა
							</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.4.1</div>
						<div className={st.date}>21.04.2022</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>განახლდა სამი ლიდერბორდის პრომო</li>
							<li>
								პრომოს ღილაკი გამოჩნდა დესკტოპ და მობილურ ვერსიებში
							</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.4.0</div>
						<div className={st.date}>19.04.2022</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>FREEVOLT ბალანსი აღარ გახსნის სალაროს ფანჯარას</li>
							<li>"გააქეშაუთე ყველა" ღილაკი აჩვენებს ქეშაუთების ჯამს</li>
							<li>გასწორდა ბალანსის ვიზუალი მობილურ ვერსიაში</li>
							<li>
								მობილურ ვერსიაში ტირაჟების ისტორია გადმოვიდა თამაშის
								გვერდზე
							</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.3.1</div>
						<div className={st.date}>09.04.2022</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>გამოსწორდა ბილეთის დუბლირების პრობლემა</li>
							<li>შეიცვალა FREEVOLT ბალანსის ვიზუალი</li>
							<li>FREEVOLT ბალანსი გადმოადგილდა მარცხნივ</li>
							<li>
								სალაროს ფანჯარა გაიხსნება მხოლოდ რეალური ბალანსის
								დაჭერის შემთხვევაში
							</li>
							<li>გასწორდა ჯეკპოტის ვიზუალი</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.3.0</div>
						<div className={st.date}>28.03.2022</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>განახლდა ჯეკპოტის ვიზუალი</li>
							<li>ბალანსის კლიკზე გამოდის სალაროს ფანჯარა</li>
							<li>
								ბილეთის განთავსებისას, არასაკმარისი ბალანსის
								შემთხვევაში, გამოჩნდება სალაროს ფანჯარა
							</li>
							<li>დაემატა ჯეკპოტის ანიმაცია და ხმა</li>
							<li>
								მობილურ ვერსიაში, როცა ვოლტი გადალახავს ჯეკპოტის
								ბარიერს, ხმასთან ერთად ჩაირთვება ვიბრაცია
							</li>
							<li>შეიცვალა ფსონის და ქეშაუთის ველების ვიზუალი</li>
							<li>ფსონის და ქეშაუთის ველებს დაემატა - და + ღილაკები</li>
							<li>ბილეთის ვიზუალი დაიხვეწა მობილურ ვერსიაში</li>
							<li>დაემატა პრომოს ღილაკი</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.2.2</div>
						<div className={st.date}>25.03.2022</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>განახლდა ბილეთების სვეტის ვიზუალი</li>
							<li>გასწორდა ხარვეზები</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.2.1</div>
						<div className={st.date}>24.03.2022</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>გასწორდა ბალანსების ვიზუალური ხარვეზი</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.2.0</div>
						<div className={st.date}>24.03.2022</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>ბილეთის დამატების ცენტრალური ღილაკი</li>
							<li>განახლდა ჯეკპოტების ანიმაცია</li>
							<li>განახლდა ჯეპოტების ცფირების ვიზუალი</li>
							<li>ჯეკპოტების ათასობითი დაყოფა</li>
							<li>ბალანსის ათასობითი დაყოფა</li>
							<li>
								ბილეთების მთვლელი აჩვენებს მიმდინარე/სულ შესაძლებელი
								ბილეთების რაოდენობას
							</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.1.4</div>
						<div className={st.date}>23.03.2022</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>განახლდა ჩატის ვიზუალი</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.1.3</div>
						<div className={st.date}>22.03.2022</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>განახლდა წესების გვერდი</li>
							<li>გასწორდა FREEVOLT ღილაკი მობილურ ვერსიაში</li>
							<li>მობილურ ვერსიაში დაემატა ვერსიის ინდიკატორი</li>
							<li>გასწორდა ხარვეზები</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.1.2</div>
						<div className={st.date}>21.03.2022</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>ისტორიაში მულტი-ბეთის ჩაკეცვა/გახსნა</li>
							<li>გადალაგდა მულტიბეთ ღილაკები</li>
							<li>გასწორდა ხარვეზები</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.0.19</div>
						<div className={st.date}>20.03.2022</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>გამოსწორდა ანიმაციის პრობლემები</li>
							<li>შეტყობინება ქსელის წყვეტის შესახებ</li>
							<li>შეტყობინება ტექნიკური პრობლემის შესახებ</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.0.18</div>
						<div className={st.date}>19.03.2022</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>ჯეკპოტზე დაჭერისას გაიხსნება ჯეკპოტის წესები</li>
							<li>განახლდა მობილური ვერსია</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.0.17</div>
						<div className={st.date}>18.03.2022</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>განახლდა მობილური ვერსიის ნავიგაცია</li>
							<li>განახლდა დახმარების გვერდი</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.0.16</div>
						<div className={st.date}>13.03.2022</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>
								ტირაჟის შემოწმების გვერდზე გადასვლილსას, ავტომატურად
								ჩაისქროლება მიმდინარე არ არჩეულ ტირაჟზე
							</li>
							<li>გასწორდა ქეშაუთ ღილაკის ვიზუალური ხარვეზი</li>
							<li>გასწორდა ბილეთის ბეთ და ქეშაუთ ველები</li>
							<li>
								ჩატის პანელის გახსნისას ჩაისქროლება ბოლო შეტყობინებამდე
							</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.0.15</div>
						<div className={st.date}>24.02.2022</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>გამოსწორდა ნავიგაციის ცხარვეზი მობილურ ვერსიაში</li>
							<li>
								როცა FREEVOLT ბალანსი ამოიწურება, ყველა შესაბამისი
								ბილეთი გარდაიქმება სტანდარტულად
							</li>
							<li>გამოსწორდა ბილეთში ქეშაუთ ღილაკის ხარვეზი</li>
							<li>FREEVOLT ბილეთზე ფონის ღილაკი შეიცვალა</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.0.14</div>
						<div className={st.date}>23.02.2022</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>
								ჯეკპოტის გათამაშების ფანჯარაში გამოჩნდება, თუ რომელი
								ჯეკპოტი გათამაშდა
							</li>
							<li>
								ისტორიაში FREEVOLT ფსონი აღინიშნება ინდივიდუალური
								სიმბოლოთი და ფერით
							</li>
							<li>გამოსწორდა ხარვეზები</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.0.13</div>
						<div className={st.date}>22.02.2022</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>ფრისპინ ბილეთში მოგების ჩვენება ფსონის გამოკლებით</li>
							<li>
								ბილეთების რაოდენობის შეზღუდვა ფრისპინ ბილეთების ჩათვლით
							</li>
							<li>
								ჯამური ფსონის შედარება ბალანსებთან ბილეთის განთავსებამდე
							</li>
							<li>ხარვეზების გასწორება</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.0.12</div>
						<div className={st.date}>20.02.2022</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>განახლების ისტორია</li>
							<li>ჩატვირთვის ინდიკატორი</li>
							<li>ხარვეზების გასწორება</li>
							<li>ინგლისური და რუსული ლოკალიზაცია</li>
							<li>ფრისპინ ბილეთის ვიზუალური განახლება</li>
						</ul>
					</div>
				</article>

				<article>
					<h5 className={st.head}>
						<div className={st.version}>v6.0.11</div>
						<div className={st.date}>10.02.2022</div>
					</h5>
					<div className={st.body}>
						<ul>
							<li>განახლებული ინტერფეისი</li>
							<li>რამოდენიმე ფსონის ერთობლივად განთავსება</li>
							<li>გაიზარდა ფსონის ლიმიტები</li>
							<li>ფსონის ავტომატურად განთავსების მექანიზმი</li>
							<li>ავტომატური ქეშაუთის მექანიზმი</li>
							<li>ქეშაუთის ჩართვა/გამორთვა</li>
							<li>ფერისპინ ფსონების სისტემა</li>
							<li>რამოდენიმე ბილეთზე ჯგუფური ოპერაციები</li>
							<li>ლიმიტის მიღწევის შემთხვევაში ავტომატური ქეშაუთი</li>
							<li>ბილეთების კონფიგურაციის შენახვა/აღდგენა</li>
							<li>
								ბილეთების რაოდენობის, ჯამური ფსონის და შესაძლო მოგების
								ინდიკატორი
							</li>
							<li>სტანდარტული და ფრისპინ ბალანსი</li>
							<li>ჩათის განახლებული ინტერფეისი</li>
							<li>ქართული კლავიატურა ჩატში</li>
							<li>ემოჯი კოდების ავტომატური კონვერტაცია ჩატში</li>
							<li>
								ემოჯი პანელი: ემოჯის ძებნა კატეგორიის მიხედვით ან
								საძიებო სიტყვით
							</li>
							<li>
								GIF პანელი: დღის ტრენდული გიფები, ძებნა საძიებო სიტყვით
							</li>
							<li>ჩატში გასაგზავნი ტქსტის ლიმიტი</li>
							<li>ჩატის პანელის ჩართვა/გამორთვა/ზომის ცვლილება</li>
							<li>ჩატის პანელის კონფიგურაციის შენახვა/აღდგენა</li>
							<li>პარალელური ავტორიზაციის შეზღუდვა</li>
							<li>ქსლის წყვეტის შემთხვევაში კავშირის განახლება</li>
							<li>განახლებული მობილური ვერსია</li>
						</ul>
					</div>
				</article>
			</Modal.Body>
		</ModalApp>
	)
})

export default Changelog
